/* eslint-disable no-unused-vars */
import "./scss/main.scss";
// import "./scss/main.css";
import "./index.css";
import "./App.css";
import Home from "./pages";
import { Route, Switch } from "react-router-dom";
import WalletModal from "./components/walletModal";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
  injected,
  walletconnect,
  CoinbaseWallet,
} from "./web3/wallet/connector";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

function App() {
  const [open, setOpen] = useState(false);
  const { account, activate, deactivate, error } = useWeb3React();
  // useEffect(() => {
  //   if (error instanceof UnsupportedChainIdError) {
  //     toast.error("Unsupported Chain, Supported chain is 1");
  //   }
  //   if (account) {
  //     toast.success("WALLET CONNECTED, YOU CAN MINT NOW");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [error, account]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const connectWallet = async (connectTo, name) => {
    try {
      if (name === "Metamask") {
        if (window.ethereum) {
          await activate(connectTo);
          window.localStorage.setItem("WEB3_REACT_CURRENT_PROVIDER", name);
        } else {
          window.location.href = `https://metamask.app.link/dapp/${window.location.host}`;
        }
      } else {
        await activate(connectTo);
        window.localStorage.setItem("WEB3_REACT_CURRENT_PROVIDER", name);
      }
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   const localProvider = window.localStorage.getItem(
  //     "WEB3_REACT_CURRENT_PROVIDER"
  //   );

  //   if (localProvider === "Metamask") {
  //     connectWallet(injected, "Metamask");
  //   } else if (localProvider === "WalletConnect") {
  //     connectWallet(walletconnect, "WalletConnect");
  //   } else if (localProvider === "CoinBase") {
  //     connectWallet(CoinbaseWallet, "CoinBase");
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const connectHandler = () => {
    if (!account) {
      handleOpen();
    } else if (account) {
      deactivate();
      window.localStorage.clear();
      window.location.reload();
    }
  };
  return (
    <div className="App">
      <Toaster />
      <Switch>
        <Route exact path="/">
          <Home connect={connectHandler} />
        </Route>
      </Switch>
      <WalletModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
        connect={connectWallet}
      />
    </div>
  );
}

export default App;
