/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import Logo from "../../images/OFFICIAL_LOGO.png";

export default function Header() {
  return (
    <div>
      <div className="div-block-527 frosty">
        <div className="paddings header">
          <div
            data-collapse="medium"
            data-animation="default"
            data-duration="400"
            data-doc-height="1"
            data-easing="ease"
            data-easing2="ease"
            role="banner"
            className="navbar w-nav"
          >
            <div
              className="container-2 w-container"
              style={{ alignItems: "center" }}
            >
              <a
                href="/"
                aria-current="page"
                className="brand w-nav-brand w--current"
              >
                <img
                  src={Logo}
                  style={{ height: "50px" }}
                  className
                  loading="lazy"
                  alt="Gutter Cat Gang Dao Logo"
                />
              </a>
              <div className="div-block-659">
                <div className="div-block-7">
                  <a
                    href="/"
                    aria-current="page"
                    className="menu w-nav-link w--current"
                  >
                    HOME
                  </a>
                  <a href="#ecosystem " className="menu w-nav-link">
                    Ecosystem
                  </a>
                  <a href="#mint" className="menu w-nav-link">
                    Mint
                  </a>
                  <a
                    href="https://opensea.io/collection/allstar-metaverse"
                    target="_blank"
                    rel="noreferrer"
                    className="menu w-nav-link"
                  >
                    Marketplace
                  </a>
                  <link rel="prerender" href="#" />
                  <a href="#faq" className="menu w-nav-link">
                    FAQ
                  </a>
                  <a href="#charts" className="menu w-nav-link">
                    Charts
                  </a>
                  <a
                    href="https://docs.allstarmetaverse.org/"
                    target={"_blank"}
                    className="menu w-nav-link"
                    rel="noreferrer"
                  >
                    Whitepaper
                  </a>
                </div>

                <div className="cloneable-area">
                  <div className="c-nav-menu-btn">
                    <a
                      href="https://mint.allstarmetaverse.org/"
                      rel="noreferrer"
                      target={"_blank"}
                      className="link-block hero-btn w-inline-block"
                    >
                      <div className="div-block-537 hero-btn">
                        <div className="button-text"> Mint</div>
                      </div>
                    </a>
                  </div>
                  <div className="c-nav-menu-overlay">
                    <div className="c-nav-menu-content">
                      <div className="nav-social-address head menu">menu</div>
                      <div className="c-col-menu-header">
                        <div className="col-menu-header-text">Menu</div>
                      </div>
                      <nav className="c-col-menu-links">
                        <div className="_w-col-menu-links">
                          <div className="c-menu-item">
                            <div className="list-img-parent">
                              <div className="card-whipe bg-cream-shade"></div>
                            </div>
                            <a
                              href="#"
                              className="c-menu-item-link w-inline-block"
                            >
                              <div className="_w-menu-item-link">
                                <div className="c-menu-item-number">
                                  <div className="menu-item-number">01</div>
                                </div>
                                <div className="c-menu-item-text">
                                  <div className="menu-item-text">
                                    Marketplace
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="c-menu-item">
                            <div className="list-img-parent">
                              <div className="card-whipe bg-cream-shade _2"></div>
                            </div>
                            <a
                              href="#"
                              className="c-menu-item-link team w-inline-block"
                            >
                              <div className="_w-menu-item-link">
                                <div className="c-menu-item-number">
                                  <div className="menu-item-number">02</div>
                                </div>
                                <div className="c-menu-item-text">
                                  <div className="menu-item-text">
                                    Whitepaper
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="c-menu-item">
                            <div className="list-img-parent">
                              <div className="card-whipe bg-cream-shade _2 _08"></div>
                            </div>
                            <a
                              href="#"
                              className="c-menu-item-link team w-inline-block"
                            >
                              <div className="_w-menu-item-link">
                                <div className="c-menu-item-number">
                                  <div className="menu-item-number">03</div>
                                </div>
                                <div className="c-menu-item-text">
                                  <div className="menu-item-text">
                                    Whitepaper
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="c-menu-item">
                            <div className="list-img-parent">
                              <div className="card-whipe bg-cream-shade _3"></div>
                            </div>
                            <a
                              href="#"
                              className="c-menu-item-link w-inline-block"
                            >
                              <div className="_w-menu-item-link">
                                <div className="c-menu-item-number">
                                  <div className="menu-item-number">04</div>
                                </div>
                                <div className="c-menu-item-text">
                                  <div className="menu-item-text">FAQ</div>
                                </div>
                              </div>
                            </a>
                          </div>
                          <div className="c-menu-item">
                            <div className="list-img-parent _4">
                              <div className="card-whipe bg-cream-shade image-4"></div>
                            </div>
                            <a
                              href="#"
                              className="c-menu-item-link w-inline-block"
                            >
                              <div className="_w-menu-item-link">
                                <div className="c-menu-item-number">
                                  <div className="menu-item-number">05</div>
                                </div>
                                <div className="c-menu-item-text">
                                  <div className="menu-item-text contact">
                                    Contacts
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>

                          <div className="c-menu-item">
                            <div className="list-img-parent _4">
                              <div className="card-whipe bg-cream-shade image-4"></div>
                            </div>
                            <a
                              href="#"
                              className="c-menu-item-link w-inline-block"
                            >
                              <div className="_w-menu-item-link">
                                <div className="c-menu-item-number">
                                  <div className="menu-item-number">06</div>
                                </div>
                                <div className="c-menu-item-text">
                                  <div className="menu-item-text contact">
                                    Docs
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </nav>
                    </div>
                    <div className="c-nav-menu-overlay-bg">
                      <div className="nav-menu-overlay-bg"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div-block-530"></div>
      </div>

      <div className="cursor-wrapper">
        <div className="large-cursor">
          <div
            data-w-id="e66d3276-0a78-0af9-6ef9-d427e9b8ceab"
            className="text-cursor"
          >
            DRAG
          </div>
        </div>
        <div className="dot-container">
          <div className="dot"></div>
        </div>
      </div>
    </div>
  );
}
