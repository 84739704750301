import React from "react";

import Logo from "../../images/OFFICIAL_LOGO.png";
import Twitter from "../../images/twitter.svg";
import Telegram from "../../images/telegram.svg";
import GitBook from "../../images/gitbook.png";
import Discord from "../../images/discord.svg";

export default function Footer() {
  return (
    <div>
      <div className="footer wf-section">
        <div className="div-block-590"></div>
        <div className="paddings">
          <div className="div-block-591">
            <a
              href="/"
              aria-current="page"
              className="link-block-90 w-inline-block w--current"
            >
              <img
                src={Logo}
                className="footer_logo"
                style={{ maxWidth: 150 }}
                loading="lazy"
                alt="NBA DUNKS"
              />
            </a>
            {/* <div
              id="w-node-_28545503-cb04-2b30-9572-c3ead0db2bb2-d0db2bac"
              className="div-block-592"
            >
              <a href="/" className="link-block-79 w-inline-block w--current">
                <div className="menu">Copyright</div>
              </a>
              <a href="#" className="link-block-79 w-inline-block">
                <div className="menu">Privacy Policy </div>
              </a>
              <a href="#" className="link-block-79 w-inline-block">
                <div className="menu">Terms and conditions </div>
              </a>
            </div> */}
            <div
              id="w-node-_28545503-cb04-2b30-9572-c3ead0db2bbf-d0db2bac"
              className="div-block-593"
            >
              <a
                href="https://twitter.com/AllstarEth"
                target="_blank"
                className="link-block-80 w-inline-block"
                rel="noreferrer"
              >
                <img
                  src={Twitter}
                  loading="lazy"
                  alt="Twitter Logo"
                  className="image-3"
                />
              </a>
              <a
                href="https://t.me/AllstarMetaverse"
                target="_blank"
                className="link-block-80 w-inline-block"
                rel="noreferrer"
              >
                <img
                  src={Telegram}
                  style={{ filter: "invert(29%) brightness(180%)" }}
                  loading="lazy"
                  alt="Telegram logo"
                  className="image-3"
                />
              </a>
              <a
                href="https://docs.allstarmetaverse.org/"
                target="_blank"
                style={{ filter: "brightness(80%) invert(80%)" }}
                className="link-block-80 w-inline-block"
                rel="noreferrer"
              >
                <img
                  src={GitBook}
                  loading="lazy"
                  alt="Gitbook logo"
                  className="image-3 full-screen-menu"
                />
              </a>
              <a
                href="https://discord.gg/allstarmetaverse"
                target="_blank"
                className="link-block-80  w-inline-block"
                rel="noreferrer"
              >
                <img
                  src={Discord}
                  loading="lazy"
                  alt="Discord logo"
                  className="image-3"
                />
              </a>
              <a
                href="https://raritysniper.com/nft-drops-calendar"
                target="_blank"
                className="link-block-80 last w-inline-block"
                rel="noreferrer"
              >
                <img
                  src={"/verify.png"}
                  loading="lazy"
                  alt="Discord logo"
                  className="image-3"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="div-block-590"></div>
        <div className="paddings footer">
          <div className="div-block-591 last-footer">
            <div>
              <div className="text-block-23">Allstar Metaverse.</div>
              <div className="text-block-23" style={{ marginTop: 10 }}>
                Disclaimer: Ethereum uses a proof-of-stake-based consensus
                mechanism, with a novel Proof of History consensus protocol.
                Allstar Metaverse is a platform proposing a series of innovative
                and fresh concepts. Therefore, matters described in
                <a
                  href="http://allstarmetaverse.org/"
                  style={{
                    display: "inline-block",
                    margin: "0 4px",
                    color: "inherit",
                  }}
                >
                  AllstarMetaverse.org
                </a>{" "}
                are subject to potential future changes and create opportunities
                for new discoveries that could require rethinking some of the
                initial assumptions and axioms. We will adjust the plan
                accordingly. Any new endeavors are filled with discoveries and
                ideas, and we plan to capitalize on them and adjust to them. The
                Project's Vision Statement outlines the vision of the Allstar
                Metaverse, however, it does not represent a complete feature
                list for the long term. Note that the information provided by
                <a
                  href="http://allstarmetaverse.org/"
                  style={{
                    display: "inline-block",
                    margin: "0 4px",
                    color: "inherit",
                  }}
                >
                  AllstarMetaverse.org
                </a>{" "}
                is not complete and may be changed. This is not an offer to sell
                or purchase securities. The securities being discussed here have
                not been registered under any federal or state securities laws.{" "}
                <a
                  href="http://allstarmetaverse.org/"
                  style={{
                    display: "inline-block",
                    margin: "0 4px",
                    color: "inherit",
                  }}
                >
                  AllstarMetaverse.org
                </a>{" "}
                is confidential and meant only for select accredited investors
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
