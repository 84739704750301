import Web3 from "web3";
import { contractAbi, contractAddress } from "../contracts/primary";
import toast from "react-hot-toast";

export const shortAddress = (str) => {
  if (str) {
    if (str.length < 10) return str;
    return `${str.slice(0, 5)}...${str.slice(-5)}`;
  } else {
    return "";
  }
};
export const shortAddressWithParams = (str, number) => {
  if (str.length < 36) return str;
  return `${str.slice(0, number)}...${str.slice(-number)}`;
};

export const toHex = (num) => {
  const val = Number(num);
  return `0x${val.toString(16)}`;
};

export const formatFromWei = (str) => {
  if (str) {
    if (str.length < 1) return str;
    return Web3.utils.fromWei(str, "ether");
  }
};
export const formatTomWei = (str) => {
  if (str) {
    if (str.length < 1) return str;
    return Web3.utils.toWei(str, "ether");
  }
};

export const getContract = async (library) => {
  try {
    const _contract = await new library.eth.Contract(
      contractAbi,
      contractAddress
    );
    return { ok: true, contract: _contract };
  } catch (error) {
    console.log(error);
    return { ok: false, contract: null };
  }
};

export const getUserBalance = async (library, account) => {
  try {
    let balance = await library.eth.getBalance(account);

    return +balance;
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export const mint = async (library, input) => {
  const { ok, contract } = await getContract(library);

  if (!ok) {
    toast.error(
      "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"
    );
    return;
  }
  if (!input || Number(input) <= 0) {
    toast.error("Invalid Input!");
    return;
  }
  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  // const price = await contract.methods.getCost().call();
  const finalPrice = Number(0) * input;
  const isSaleOpen = await contract.methods.saleOpen().call();
  // const mintLimit = await contract.methods.maxMintAmount().call();
  const balance = await contract.methods.balanceOf(accounts[0]).call();

  const userBalance = await getUserBalance(library, accounts[0]);
  console.log(userBalance, finalPrice);

  if (userBalance < 0.01) {
    toast.error("you should have minimum 0.01 ETH to mint", { id: toastId });
    return;
  }

  if (balance >= 1) {
    toast.error("Max 1 mint allowed per wallet", { id: toastId });
    return;
  }

  if (!isSaleOpen) {
    toast.error(
      "Sale is not Open Yet, Please follow our twitter and discord for updates",
      { id: toastId }
    );
    return;
  }
  try {
    contract.methods
      .mint(input)
      .send({
        from: accounts[0],
        value: finalPrice,
      })
      .on("transactionHash", function () {
        toast.loading(
          "Please wait to get confirmation of the transaction from blockchain",
          { id: toastId }
        );
      })
      .on("confirmation", function (receipt) {
        console.log("CONFIRMATION", receipt);
      })
      .on("receipt", async function (receipt) {
        toast.success("NFTs minted successfully", { id: toastId });

        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          toast.error("Transaction Rejected", { id: toastId });
        } else {
          toast.error("Transaction Failed", { id: toastId });
        }
      });
  } catch (error) {
    console.log(error);
  }
};
