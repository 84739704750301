/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Lottie from "react-lottie-player";
import ReactPlayer from "react-player";
import BannerVideo from "../images/bg-video.mp4";
import BannerVideoMobile from "../images/bg-mobile.mp4";

// import "./../scss/main.scss";
import ReactBootstrap, {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";

import $ from "jquery";

// json File
import dataImage from "../images/data.json";
import circle from "../images/circle_moving.json";

//images
import Logo from "../images/OFFICIAL_LOGO.png";
import Twitter from "../images/twitter.svg";
import Telegram from "../images/telegram.svg";
import GitBook from "../images/gitbook.png";
import Discord from "../images/discord.svg";

import Group_logo from "../images/Group_logo.png";
import MainImage from "../images/Main image.png";
import Passive_Income from "../images/Passive_Income.png";
import Token_NFT from "../images/NFT__Token.png";
import All_Star_Partnerships from "../images/Partnership.png";
import Collectible from "../images/collectible.png";
import StaySaucy from "../images/Stay_Saucy.png";

import Image1 from "../images/sol-header-visual.webp";
import Arrow2 from "../images/60eeacde5628ed8ecc165492_arrow_2.svg";
import Image2 from "../images/60f8175249906ed54d09c689_Frame 17.png";
import Image3 from "../images/60f052a289fbfe5964780e95_image_3.png";
import Announce from "../images/60f0535592383362d2383824_announce.png";

import Team1_1 from "../images/core_pics/Alpha.png";
import Team1_2 from "../images/core_pics/Blockchainer.png";
import Team1_3 from "../images/core_pics/Maverick.png";
import Team1_4 from "../images/core_pics/Vanderhause.png";
import Team1_5 from "../images/core_pics/Xiu.png";
// import Team1_5 from "../images/users/5.png";
// import Team1_6 from "../images/users/6.png";
// import Team1_7 from "../images/users/7.png";

import Team2_1 from "../images/admin_pics/SaVaGe.png";
import Team2_2 from "../images/admin_pics/BigWilly.png";
import Team2_3 from "../images/admin_pics/FarmerBoy.png";
import Team2_4 from "../images/admin_pics/Calcifer.png";
import Team2_5 from "../images/admin_pics/Babymania.png";
import Team2_6 from "../images/admin_pics/Phillipe.png";
import Team2_7 from "../images/admin_pics/Lyle.png";

import Sailor from "../images/sailor.png";
import MintingImage from "../images/Minting_Section.png";

//Video
import Video from "../images/14.mp4";
import VideoMint from "../images/ball_2.mp4";
import MintNFT from "../components/MintNFT";
import { getTrackBackground, Range } from "react-range";
import { useWeb3React } from "@web3-react/core";
import { getContract, mint } from "../web3/helpers";
// import * as ReactBootstrap from 'react-bootstrap';
function Home({ connect }) {
  const { account, library } = useWeb3React();
  const [value, setValue] = useState(1);
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(10000);
  const STEP = 1;
  const MIN = 1;
  const MAX = 20;
  const [values, setValues] = React.useState([3]);

  //Slider

  const initialItems = Array.apply(null, Array(6)).map(
    (value, index) => index + 1
  );

  const ref = useRef();
  const [items, setItems] = useState(initialItems);
  function addItems() {
    setItems((items) => [...items, items.length + 1]);
  }
  function resetItems() {
    setItems(initialItems);
  }
  function goToLastSlide() {
    ref.current.splide.go(ref.current.splide.length - 1);
  }

  //animated background

  let [slider, setSlider] = useState(false);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "white" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "white" }}
        onClick={onClick}
      />
    );
  }

  //button Plus Minus

  $(document).ready(function () {
    $(".minus").click(function () {
      var $input = $(this).parent().find("input");
      var count = parseInt($input.val()) - 1;
      count = count < 1 ? 1 : count;
      $input.val(count);
      $input.change();
      return false;
    });
    $(".plus").click(function () {
      var $input = $(this).parent().find("input");
      $input.val(parseInt($input.val()) + 1);
      $input.change();
      return false;
    });
  });

  //button plus end minus

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  useEffect(() => {
    const getData = async () => {
      if (account) {
        const { ok, contract } = await getContract(library);
        if (!ok) {
          return;
        }
        try {
          const MAX_SUPPlY = await contract.methods.maxSupply().call();
          setMaxSupply(MAX_SUPPlY);
          const totalSupply = await contract.methods.totalSupply().call();
          setTotalSupply(totalSupply);
        } catch (error) {
          console.log(error);
        }
      }
    };
    getData();
  }, [account, library]);
  const mintHandler = () => {
    if (!account) {
      connect();
      return;
    }

    mint(library, 1);
  };

  return (
    <>
      <Header />

      {/* <section className="Bgcolor">
        <div className="div-block html-embed">
          <div className="html-embed w-embed"></div>
        </div>
      </section> */}

      <section
        className="Bgcolor"
        style={{ position: "relative", paddingBottom: 20 }}
      >
        <video
          src={BannerVideo}
          className="md-show
           "
          style={{ width: "100%", position: "absolute", top: "0" }}
          loop
          autoPlay
          muted
          playsInline
        ></video>
        <video
          src={BannerVideoMobile}
          className="md-hide"
          style={{ width: "100%", position: "absolute", top: "0" }}
          loop
          autoPlay
          muted
          playsInline
        ></video>
        <div
          className="section wf-section"
          style={{ position: "relative", backgroundColor: "transparent" }}
        >
          <div className="paddings">
            <div className="div-block-532 " style={{ paddingTop: 50 }}>
              <div>
                <h1 className="heading heading-c">
                  <span className="text-span">LET'S BE LOUD AND CLEAR! </span>
                  IT’S ALLSTAR TIME NOW
                </h1>
                <p className="paragraph">
                  Allstar Metaverse deploys a dynamic & residual income
                  ecosystem that rewards loyal fans, powered by DUNK NFTs and
                  $STAR Token. DUNK is a stubborn Gorilla that was born with a
                  Basketball . He was so good, that even big king ape Kobe
                  recognized his talent. Easily slam-dunking on many famous
                  teams, swinging through the world’s cities, and smashing the
                  biggest brands in pop culture to generate you dynamic income.
                </p>

                <p className="paragraph">
                  Project launching Soon 🔥 Keep Thirsty On Us!
                </p>

                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                    marginTop: "20px",
                  }}
                >
                  <a
                    href="https://discord.gg/allstarmetaverse"
                    target="_blank"
                    className="link-block hero-btn w-inline-block"
                    rel="noreferrer"
                  >
                    <div className="div-block-537 hero-btn">
                      <div className="button-text">$Star Private Sale</div>
                    </div>
                  </a>

                  <a
                    href="https://discord.gg/allstarmetaverse"
                    target="_blank"
                    className="link-block hero-btn w-inline-block"
                    rel="noreferrer"
                  >
                    <div className="div-block-537 hero-btn">
                      <div className="button-text">Join Discord</div>
                    </div>
                  </a>
                </div>

                <div className="exlusive">
                  <p>Exclusively On</p>
                  <img src={Group_logo} style={{ maxWidth: 180 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Bgcolor">
        {/* <div
          id="the-dao"
          data-w-id="42682a84-4099-a00e-2343-ac795c1c1589"
          className="section-2 wf-section"
        >
          <div className="paddings">
            <div>
              <h1 className="heading-2">
                WELCOME TO{" "}
                <span className="text-span-2">ALLSTAR METAVERSE</span>
              </h1>
            </div>

            <div className="div-block-539">
              <div id="w-node-cbc82d3b-3bc1-d210-3f0e-32b35f07fec5-6aec71e4">
                <div>
                  <div className="text-block-3">STAY SAUCY PLAYER.</div>
                  <p className="paragraph">
                    Well, to start off, DUNK NFTs are the first-ever Hypebeast
                    and dynamic income generating NFTs on the Ethereum
                    blockchain. That means that you unlock the opportunity to
                    earn dynamic income just by holding onto them! Dope? Ok, but
                    that's not all. DUNKs are unique and unstoppable NFTs that
                    will always be in demand. It's the perfect investment for
                    anyone looking for a high-value, dynamic income producing
                    asset.
                  </p>
                  <div className="div-block-542">
                    <div className="div-block-543"></div>
                    <div>
                      <p className="paragraph">
                        DUNK NFTs are also extremely secure and tamper-proof due
                        to the use of blockchain technology. So you can rest
                        assured that your assets are in good hands. Lastly, DUNK
                        NFTs are also extremely easy to use.
                      </p>
                      <p className="paragraph">
                        All you need is an Ethereum Wallet to store them and
                        you're good to go!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-node-ba449b26-fc19-4a72-8c75-9cf1f434ec5f-6aec71e4"
                className="div-block-544"
              ></div>
            </div>
          </div>
        </div> */}
        <div className="">
          <video
            src="/assets/videos/welcome_desktop.mp4"
            style={{ width: "100%" }}
            className="desktop"
            loop
            muted
            autoPlay
            playsInline
          ></video>
          <video
            src="/assets/videos/welcome_mobile.mp4"
            style={{ width: "100%" }}
            className="mobile"
            loop
            muted
            autoPlay
            playsInline
          ></video>
        </div>
        <div className="campfireAbout">
          <div className="container">
            <div className="mo-fire">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="https://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="115px"
                height="89.864px"
                viewBox="0 0 125 189.864"
                enable-background="new 0 0 125 189.864"
              >
                <path
                  className="flame-main"
                  fill="#F36E21"
                  d="M76.553,186.09c0,0-10.178-2.976-15.325-8.226s-9.278-16.82-9.278-16.82s-0.241-6.647-4.136-18.465
          c0,0,3.357,4.969,5.103,9.938c0,0-5.305-21.086,1.712-30.418c7.017-9.333,0.571-35.654-2.25-37.534c0,0,13.07,5.64,19.875,47.54
          c6.806,41.899,16.831,45.301,6.088,53.985"
                />
                <path
                  className="flame-main one"
                  fill="#F6891F"
                  d="M61.693,122.257c4.117-15.4,12.097-14.487-11.589-60.872c0,0,32.016,10.223,52.601,63.123
          c20.585,52.899-19.848,61.045-19.643,61.582c0.206,0.537-19.401-0.269-14.835-18.532S57.576,137.656,61.693,122.257z"
                />
                <path
                  className="flame-main two"
                  fill="#FFD04A"
                  d="M81.657,79.192c0,0,11.549,24.845,3.626,40.02c-7.924,15.175-21.126,41.899-0.425,64.998
          C84.858,184.21,125.705,150.905,81.657,79.192z"
                />
                <path
                  className="flame-main three"
                  fill="#FDBA16"
                  d="M99.92,101.754c0,0-23.208,47.027-12.043,80.072c0,0,32.741-16.073,20.108-45.79
          C95.354,106.319,99.92,114.108,99.92,101.754z"
                />
                <path
                  className="flame-main four"
                  fill="#F36E21"
                  d="M103.143,105.917c0,0,8.927,30.753-1.043,46.868c-9.969,16.115-14.799,29.041-14.799,29.041
          S134.387,164.603,103.143,105.917z"
                />
                <path
                  className="flame-main five"
                  fill="#FDBA16"
                  d="M62.049,104.171c0,0-15.645,67.588,10.529,77.655C98.753,191.894,69.033,130.761,62.049,104.171z"
                />
                <path
                  className="flame"
                  fill="#F36E21"
                  d="M101.011,112.926c0,0,8.973,10.519,4.556,16.543C99.37,129.735,106.752,117.406,101.011,112.926z"
                />
                <path
                  className="flame one"
                  fill="#F36E21"
                  d="M55.592,126.854c0,0-3.819,13.29,2.699,16.945C64.038,141.48,55.907,132.263,55.592,126.854z"
                />
                <path
                  className="flame two"
                  fill="#F36E21"
                  d="M54.918,104.595c0,0-3.959,6.109-1.24,8.949C56.93,113.256,52.228,107.329,54.918,104.595z"
                />
              </svg>
            </div>

            <div className="centerHeading">
              <h2>
                THE ALLSTAR <span>METAVERSE</span>
              </h2>
              <p style={{ display: "none" }}>
                The world’s first and largest digital marketplace for crypto
                collectibles and non- fungible tokens (NFTs)
              </p>
            </div>

            <div className="row pt-5">
              <div className="col-lg-4 col-md-6">
                <div className="campfireBox">
                  <img src={Passive_Income} />
                  <h4>Income Producing Ecosystem</h4>
                  <p style={{ marginBottom: "45px" }}>
                    Allstar Metaverse deploys a dynamic & residual income
                    ecosystem that rewards loyal fans, powered by DUNK NFTs and
                    $STAR Token.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="campfireBox">
                  <img src={Token_NFT} />
                  <h4>Token and NFT Integration</h4>
                  <p style={{ marginBottom: "45px" }}>
                    $STAR Token is the fuel that powers the Allstar Metaverse.
                    It will be uniquely integrated with our NFTs, and utility
                    will be based on holding & interacting rather than
                    transacting.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="campfireBox">
                  <img src={All_Star_Partnerships} />
                  <h4>All-Star Partnerships</h4>
                  <p style={{ marginBottom: "45px" }}>
                    Allstar Metaverse collaborates with top brands & athletes to
                    create unique and engaging experiences for investors. These
                    partnerships provide a wealth of content and opportunities.
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  width: "500px",
                  margin: "0 auto",
                  flexWrap: "wrap",
                }}
              >
                <a
                  href="https://docs.allstarmetaverse.org/"
                  target="_blank"
                  className="link-block hero-btn w-inline-block centerblock"
                  rel="noreferrer"
                >
                  <div className="div-block-537 hero-btn">
                    <div className="button-text"> Read whitepaper</div>
                  </div>
                </a>
                <a
                  href="#roadmap"
                  target="_blank"
                  className="link-block hero-btn w-inline-block centerblock"
                  rel="noreferrer"
                >
                  <div className="div-block-537 hero-btn">
                    <div className="button-text"> Project Vision</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="div-block-538">
          <div className="div-block-540"></div>
        </div>

        <div
          data-w-id="dafc2333-a24e-13ca-a7ec-f7f524655ec7"
          className="scroll-section"
        >
          <div className="super-text one">
            AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
          </div>
          <div className="super-text two">
            AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
            AAAAAAAAAA
          </div>
        </div>

        <div className="collectible" style={{ display: "none" }}>
          <div className="container">
            <div className="collectibleBox">
              <div className="row">
                <div className="col-lg-7">
                  <div className="collectBox">
                    <h3> Collectible and Unique </h3>
                    <h2>
                      10,000 unique, hooping, algorithmically generated gorillas
                      - stored with proof of ownership on the Solana blockchain.
                    </h2>
                    <p>
                      Every DUNK is Different, everyone has a certain rarity and
                      passive income generating abilities. We believe in Solana
                      and we believe in NFTs. In the future, we'll look at this
                      period as a defining moment in culture and technology.
                    </p>
                  </div>
                </div>

                <div className="col-lg-5">
                  <img src={Collectible} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="ecosystem"
          data-w-id="bf052fd0-4b85-b2bb-64d2-2078cdcf7aa6"
          className="section-4 wf-section"
        >
          <video
            src="/assets/videos/our_ecosystem-desktop.mp4"
            style={{ width: "100%" }}
            className="ecosystem-desktop"
            autoPlay
            loop
            muted
            playsInline
          ></video>
          <video
            src="/assets/videos/our_ecosystem-mobile.mp4"
            style={{ width: "100%" }}
            className="ecosystem-mobile"
            autoPlay
            loop
            muted
            playsInline
          ></video>
          {/* <div className="">
            <div className="div-block-548">
              <div
                id="w-node-db410f73-501e-a1c4-e8c4-ca38701e637e-6aec71e4"
                className="circle_box div-block-554"
              >
                <div className="div-block-549 ellipse-1 circles">
                  <div className="div-block-551 ">
                    <div className="text-block-4">
                      ACTIVELY MANAGED TREASURY
                    </div>
                    <p className="paragraph-3">
                      {" "}
                      The Treasury is the liquidity engine of our ecosystem.
                      Allstar Metaverse Treasury will generate revenues across
                      multiple income vectors, such as platform fees & venture
                      investments, and will be paid out to stakers in the form
                      of $STAR. It's designed to constantly grow, meaning that
                      the value of $STAR token will grow along with it.
                    </p>
                    <p className="paragraph-3">
                      {" "}
                      AllStar Metaverse will be driven by the community and its
                      involvement will be critical to its success, hence, we
                      will have Community leader representation on the Treasury
                      for any fund transfer for project related expenditure or
                      payments. The DAO is aimed at ensuring the Allstar
                      Metaverse mission to provide equal opportunities to every
                      member of the community. Similar to how boards,
                      committees, and executives run usual companies, crypto DAO
                      projects are managed by their holders who are enabled to
                      make key decisions concerning the operation, development,
                      and proposals about the future of the project.
                    </p>
                  </div>
                </div>
                <div className="div-block-555">
                  <div
                    className="div-block-552"
                    style={{ marginBottom: "30px" }}
                  >
                    <div className="div-block-647">
                      <h1 className="heading-3">
                        OUR <br />
                        <span className="text-span-6">ECOSYSTEM</span>
                      </h1>
                    </div>
                  </div>
                  <div className="div-block-549-special ellipse-1 circles">
                    <div className="div-block-551 ">
                      <div className="text-block-4">
                        ACTIVELY MANAGED TREASURY
                      </div>
                      <p className="paragraph-3">
                        {" "}
                        The Treasury is the liquidity engine of our ecosystem.
                        NBA's Treasury will generate revenues across multiple
                        income vectors, such as platform fees & venture
                        investments, and will be paid out to stakers in the form
                        of $NBA. It's designed to constantly grow, meaning that
                        the value of $NBA token will grow along with it.
                      </p>
                    </div>
                  </div>
                  <div className="div-block-553 circles">
                    <div className="div-block-551 small">
                      <div className="text-block-4 small">
                        DYNAMIC INCOME GENERATION
                      </div>
                      <p className="paragraph-3 small">
                        We've designed Allstar Metaverse ecosystem to produce
                        income in the most dynamic methods. Although Play2Earn
                        is a great way to introduce users to an ecosystem, it
                        can sometimes be time-consuming and doesn't scale.
                        That's why we're introducing a variety of residual
                        income generating methods that will keep users engaged
                        and rewarded.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="w-node-_12aa7382-b309-3551-af48-cb7ae024ae77-6aec71e4"
                className="circle_box div-block-557"
              >
                <div className="div-block-556 circles ">
                  <div className="div-block-551 small ">
                    <div className="text-block-4">NFT DEFI AUGMENTATION</div>
                    <p className="paragraph-3">
                      {" "}
                      Allstar Metaverse Ecosystem will include many DeFi
                      utilities to provide more value and bootstrap sustainable
                      liquidity for $STAR token. $STAR liquidity providers will
                      be rewarded with generous yet sustainable incentives,
                      encouraging them to provide continuous liquidity to the
                      ecosystem. DUNK NFTs amongst other utilities will be able
                      to augment all our DeFi utilities allowing for more
                      modularity and earning strategies. For example, a user can
                      increase their liquidity mining rewards by completing
                      certain activities with their DUNK NFTs.
                    </p>
                  </div>
                </div>
                <div className="div-block-558 circles">
                  <div className="div-block-551 small ">
                    <div className="text-block-4">THE METAVERSE</div>
                    <p className="paragraph-3">
                      {" "}
                      Our first interaction with the Metaverse will occur in a
                      Portals room where holders of $STAR token and DUNK NFTs
                      can come together. One of the most important aspects of
                      our Metaverse is that it will just not be a place to relax
                      and chill, but we create a one-of-a-kind Metaverse where
                      you can buy and sell properties, rent them out while
                      having fun.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      <section className="TileLine" id="roadmap">
        <div
          data-w-id="523168c7-ff3b-b566-4590-f40805b4a473"
          className="section-12 wf-section"
        >
          <div className="paddings relative" style={{ padding: "0 32px" }}>
            <div className="div-block-612">
              <div>
                <h1 className="heading-19">
                  Project <span className="text-span-12">Vision</span>
                </h1>
              </div>
            </div>
            <div className="div-block-614">
              <div className="timeline_wrapper">
                <div className="timeline_progress">
                  <div className="timeline_progress-bar"></div>
                </div>
                <div
                  data-w-id="ddebca4c-15d9-f65c-3685-fd6102b4ab84"
                  className="timeline_item"
                >
                  <div
                    id="w-node-ddebca4c-15d9-f65c-3685-fd6102b4ab85-4cec7203"
                    className="timeline_left"
                  >
                    <div className="timeline_date-text">
                      <span className="text-span-13">01</span> <br />
                      Warm-Up
                      <br />
                    </div>
                  </div>
                  <div
                    id="w-node-ddebca4c-15d9-f65c-3685-fd6102b4ab88-4cec7203"
                    className="timeline_centre"
                  >
                    <div className="timeline_circle"></div>
                  </div>
                  <div
                    id="w-node-ddebca4c-15d9-f65c-3685-fd6102b4ab8a-4cec7203"
                    className="timeline_right"
                  >
                    <div className="margin-bottom-xlarge">
                      <div className="timeline_text">
                        We begin building the Allstar Metaverse by initiating
                        DUNK NFT free mint and $STAR token pre-sale.
                      </div>
                    </div>
                    {/* <div className="timeline_image-wrapper">
                      <img src={Image1} loading="lazy" alt="NBA" />
                    </div> */}
                  </div>
                </div>
                <div
                  data-w-id="ddebca4c-15d9-f65c-3685-fd6102b4ab90"
                  className="timeline_item"
                >
                  <div
                    id="w-node-ddebca4c-15d9-f65c-3685-fd6102b4ab91-4cec7203"
                    className="timeline_left"
                  >
                    <div className="timeline_date-text">
                      <span className="text-span-13">
                        02
                        <br />‍
                      </span>
                      The Rise
                    </div>
                  </div>
                  <div
                    id="w-node-ddebca4c-15d9-f65c-3685-fd6102b4ab94-4cec7203"
                    className="timeline_centre"
                  >
                    <div className="timeline_circle"></div>
                  </div>
                  <div className="timeline_right">
                    <div className="margin-bottom-medium _02">
                      <div className="timeline_text">
                        The Allstar Metaverse will start coming to life as the
                        full supply of NFTs is minted and project treasury is
                        funded, rewards are given out, and the first series of
                        NFT tasks are released
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-w-id="ddebca4c-15d9-f65c-3685-fd6102b4aba3"
                  className="timeline_item"
                >
                  <div
                    id="w-node-ddebca4c-15d9-f65c-3685-fd6102b4aba4-4cec7203"
                    className="timeline_left"
                  >
                    <div className="timeline_date-text">
                      <span className="text-span-13">03 </span> <br />
                      Glory
                    </div>
                  </div>
                  <div
                    id="w-node-ddebca4c-15d9-f65c-3685-fd6102b4aba7-4cec7203"
                    className="timeline_centre"
                  >
                    <div className="timeline_circle"></div>
                  </div>
                  <div className="timeline_right">
                    <div className="margin-bottom-medium _03">
                      <div className="timeline_text">
                        DUNKs will become a dominant player in the NFT and DeFi
                        space. We’ll create a thriving economy centered around
                        DUNKs & Arenas NFTs as well as $STAR Token by releasing
                        our full suite of utilities that make it easy for users
                        to get started.
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-w-id="ddebca4c-15d9-f65c-3685-fd6102b4aba3"
                  className="timeline_item"
                >
                  <div
                    id="w-node-ddebca4c-15d9-f65c-3685-fd6102b4aba4-4cec7203"
                    className="timeline_left"
                  >
                    <div className="timeline_date-text">
                      <span className="text-span-13">03 </span> <br />
                      The Future
                    </div>
                  </div>
                  <div
                    id="w-node-ddebca4c-15d9-f65c-3685-fd6102b4aba7-4cec7203"
                    className="timeline_centre"
                  >
                    <div className="timeline_circle"></div>
                  </div>
                  <div className="timeline_right">
                    <div className="margin-bottom-medium _03">
                      <div className="timeline_text">
                        Allstar Metaverse will continue to innovate, by
                        introducing new features and expanding its reach to new
                        markets.
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overlay-fade-bottom"></div>
                <div className="overlay-fade-top"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="mint" className="section-7 FeelingLike">
        <div className="container-4">
          <div
            data-w-id="6d0d9e76-56eb-b9b6-0f3d-8d3645dd53dd"
            className="header"
          >
            <div className="eggs-container position-relative d-flex justify-content-center">
              <img className="egg2" src={MintingImage} />

              {/* <div className='player-wrapper'>
          <ReactPlayer
          playing
          className='react-player playerPos'
          url= {VideoMint}
          width='100%'
          height='430px'
         
          controls = {false}
          config={{ file: { attributes: {
            autoPlay: true,
            muted: true,
            loop: true
          }}}}
          />
      </div> */}
            </div>

            <div className="div-block-546">
              <h1 className="heading-2 cats-slider">
                FEELING LIKE AN <span className="text-span-7"> ALLSTAR?</span>
              </h1>

              <p className="paragraph cats-slider">
                FREE MINT + A BIG chance to mint an extremely rare LEGENDARY or
                ALLSTAR DUNK if you’re granted a WL spot!
              </p>

              {/* <form className="range">
                <div className="form-group range__slider">
                  <Range
                    // className="range-slider"
                    values={values}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    onChange={(values) => setValues(values)}
                    renderTrack={({ props, children }) => (
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                          ...props.style,
                          height: "36px",
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: "8px",
                            width: "100%",
                            borderRadius: "4px",
                            background: getTrackBackground({
                              values,
                              colors: ["#311352", "#979da2"],
                              min: MIN,
                              max: MAX,
                            }),
                            alignSelf: "center",
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          backgroundColor: "#311352",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "20px",
                          width: "20px",
                          borderRadius: "50%",
                        }}
                      >
                        <div
                          className="range-slider-label"
                          style={{
                            position: "absolute",
                            top: "-40px",
                            color: "#311352",
                            fontWeight: "500",
                            fontSize: "16px",
                            background: "#fff",
                            padding: "4px",
                            borderRadius: "4px",
                            width: 48,
                            height: 32,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          {values[0]}
                        </div>
                        <div
                          style={{
                            // backgroundColor: "#548BF4",
                            left: "50%",
                            background: "#d84be1",
                            borderRadius: "50%",
                            width: 8,
                            height: 8,
                            backgroundColor: isDragged ? "#d84be1" : "#d84be1",
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
                <div className="form-group range__value">
                  <label>1 DUNK = 2 SOL</label>
                  <span>DUNK {values[0] * 2}</span>
                </div>
              </form> */}

              <a
                className="link-block w-inline-block "
                style={{ backgroundColor: "transparent" }}
                // onClick={mintHandler}
                target="_blank"
                href="https://mint.allstarmetaverse.org"
                rel="noreferrer"
              >
                <div className="div-block-537 div-block-lg width290">
                  <div className="button-text headingFont">Mint Now</div>
                </div>
              </a>
              <br />
            </div>
          </div>
        </div>
      </section>

      <div
        data-w-id="9b26fa49-2e6e-9436-7e8d-b46439196245"
        className="section-10 relative wf-section"
      >
        {/* <div className="paddings">
      <div className="div-block-609">
        <div className="div-block-650">
          <img src={NFTImage} />
        </div>

        <div
          id="w-node-e169186a-fa5d-f3ab-0e12-763628c1ed96-4cec7203"
          className="div-block-610"
        >
          <h1 className="heading-11 transformClass">
            dynamic <span className="text-span-10">nature</span>
          </h1>
          <p className="paragraph-5 without-margin transformClass">
            One of the most interesting aspects of DAO governance is its
            dynamic nature. The rules, perimeters and boundaries are always
            in flux as they adapt to new technologies or changes in
            circumstances that help avoid otherwise inevitable corruptive
            influences.
          </p>
        </div>
      </div>
    </div> */}
      </div>

      <section className="Bgcolor" style={{ padding: "40px 0" }}>
        <div
          data-w-id="044c9a58-a94b-1bed-ac90-9c98ed0b784d"
          className="section-5 wf-section"
        >
          <div className="paddings fullscreen">
            <div className="div-block-580">
              <div className="divVideo">
                <div className="player-wrapper">
                  <ReactPlayer
                    playing
                    className="react-player playerPos"
                    url={Video}
                    width="100%"
                    height="430px"
                    controls={false}
                    config={{
                      file: {
                        attributes: {
                          autoPlay: true,
                          muted: true,
                          loop: true,
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="div-block-582">
                <div className="paddings fullscreen-paddings community">
                  <div>
                    <div className="text-block-18">Community </div>
                    <h3 className="heading-4">
                      BECOME PART OF THE{" "}
                      <span className="text-span-8">JUNGLE</span>
                    </h3>
                    <a
                      href="https://linktr.ee/NBAdunks"
                      className="link-block w-inline-block"
                    >
                      <div className="div-block-537 hero-btn">
                        <div className="button-text">Register Today</div>
                        <div className="div-block-547"></div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Our Team --> */}

      <section className="Bgcolor">
        <div
          data-w-id="e829ca5c-8269-be55-4f17-ebff9bfdfa08"
          className="section-13 wf-section"
        >
          <div className="paddings">
            <video
              src="/assets/videos/core_desktop.mp4"
              style={{ width: "100%" }}
              className="desktop"
              loop
              muted
              autoPlay
              playsInline
            ></video>
            <video
              src="/assets/videos/core_mobile.mp4"
              style={{ width: "100%" }}
              className="mobile"
              loop
              muted
              autoPlay
              playsInline
            ></video>
            <br />
            <br />
            <br />
            <video
              src="/assets/videos/admin_desktop.mp4"
              style={{ width: "100%" }}
              className="desktop"
              loop
              muted
              autoPlay
              playsInline
            ></video>
            <video
              src="/assets/videos/admin_mobile.mp4"
              style={{ width: "100%" }}
              className="mobile"
              loop
              muted
              autoPlay
              playsInline
            ></video>

            {/* <header className="section-7 paddingMobile">
              <div className="div-block-615">
                <div
                  id="w-node-ff9a4bc8-f13a-a76a-ce4b-828210563044-4cec7203"
                  className="div-block-653"
                >
                  <div className="text-block-35 transformClass">
                    Meet our multi-skilled
                  </div>
                  <h1 className="heading-14 transformClass">Core team</h1>
                </div>
              </div>
              <div
                className="container-4"
                style={{ overflow: "hidden", paddingTop: "2rem" }}
              >
                <div
                  data-w-id="8f40bda2-8430-90eb-025d-9c0853eb798f"
                  className="splide slider1"
                >
                  <div className="splide__track w-dyn-list">
                    <div
                      role="list"
                      className="splide__list w-dyn-items"
                      // style={{ width: "100%" }}
                    >
                      <Splide
                        options={{
                          rewind: false,
                          perPage: 4,
                          gap: 0,
                          breakpoints: {
                            // 1200: { perPage: 4, gap: 0 },
                            1200: { perPage: 4, gap: 0 },
                            800: { perPage: 1, gap: 0 },
                          },
                          // padding: "1rem",
                        }}
                      >
                        <SplideSlide>
                          <div
                            role="listitem"
                            className="splide__slide five-cards w-dyn-item"
                          >
                            <div className="slider-tall">
                              <div className="slider-tall_img">
                                <img
                                  src={Team1_1}
                                  loading="lazy"
                                  alt="Gutter Jonah"
                                  className="slider-tall_photo"
                                />
                              </div>
                              <div className="text-opacity">
                                <h3 className="heading-13">Alpha</h3>
                                <h3 className="sliders_text2-3">
                                  Operations Director
                                </h3>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>

                        <SplideSlide>
                          <div
                            role="listitem"
                            className="splide__slide five-cards w-dyn-item"
                          >
                            <div className="slider-tall">
                              <div className="slider-tall_img">
                                <img
                                  src={Team1_3}
                                  loading="lazy"
                                  alt="Gutter Ric"
                                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 97vw, (max-width: 991px) 52vw, (max-width: 1279px) 36vw, (max-width: 1439px) 37vw, (max-width: 1919px) 531.13134765625px, 28vw"
                                  className="slider-tall_photo"
                                />
                              </div>
                              <div className="text-opacity">
                                <h3 className="heading-13">Maverick</h3>
                                <h3 className="sliders_text2-3">
                                  Product Director
                                </h3>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>

                        <SplideSlide>
                          <div
                            role="listitem"
                            className="splide__slide five-cards w-dyn-item"
                          >
                            <div className="slider-tall">
                              <div className="slider-tall_img">
                                <img
                                  src={Team1_2}
                                  loading="lazy"
                                  alt="Gutter Ric"
                                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 97vw, (max-width: 991px) 52vw, (max-width: 1279px) 36vw, (max-width: 1439px) 37vw, (max-width: 1919px) 531.13134765625px, 28vw"
                                  className="slider-tall_photo"
                                />
                              </div>
                              <div className="text-opacity">
                                <h3 className="heading-13">Blockchainer</h3>
                                <h3 className="sliders_text2-3">
                                  Blockchain Engineer
                                </h3>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>
                 

                        <SplideSlide>
                          <div
                            role="listitem"
                            className="splide__slide five-cards w-dyn-item"
                          >
                            <div className="slider-tall">
                              <div className="slider-tall_img">
                                <img
                                  src={Team1_5}
                                  loading="lazy"
                                  alt="Gutter Dan"
                                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 97vw, (max-width: 991px) 52vw, (max-width: 1279px) 36vw, (max-width: 1439px) 37vw, (max-width: 1919px) 531.13134765625px, 28vw"
                                  className="slider-tall_photo"
                                />
                              </div>
                              <div className="text-opacity">
                                <h3 className="heading-13">Xiu</h3>
                                <h3 className="sliders_text2-3">
                                  Partnerships Director
                                </h3>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>
                       
                      </Splide>
                    </div>
                  </div>
                  <div className="splide__arrows-2">
                    <div className="splide__embed-2 w-embed">
                      <button className="splide__arrow splide__arrow--prev"></button>
                    </div>
                    <div className="splide__embed-2 w-embed">
                      <button className="splide__arrow splide__arrow--next"></button>
                    </div>
                  </div>
                </div>
              </div>
            </header> */}
          </div>
          {/* <div className="paddings">
            <header className="section-7 paddingMobile">
              <div>
                <div
                  id="w-node-ff9a4bc8-f13a-a76a-ce4b-828210563044-4cec7203"
                  className="div-block-653"
                >
                
                  <h1 className="heading-14 transformClass">ADMIN TEAM</h1>
                </div>
              </div>
              <div
                className="container-4"
                style={{ overflow: "hidden", paddingTop: "2rem" }}
              >
                <div
                  data-w-id="8f40bda2-8430-90eb-025d-9c0853eb798f"
                  className="splide slider1"
                >
                  <div className="splide__track w-dyn-list">
                    <div role="list" className="splide__list w-dyn-items">
                      <Splide
                        options={{
                          rewind: false,
                          perPage: 4,
                          gap: 0,
                          breakpoints: {
                            // 1200: { perPage: 4, gap: 0 },
                            1200: { perPage: 4, gap: 0 },
                            800: { perPage: 1, gap: 0 },
                          },
                        }}
                      >
                        <SplideSlide>
                          <div
                            role="listitem"
                            className="splide__slide five-cards w-dyn-item"
                          >
                            <div className="slider-tall">
                              <div className="slider-tall_img">
                                <img
                                  src={Team2_1}
                                  loading="lazy"
                                  alt="Gutter Jonah"
                                  className="slider-tall_photo"
                                />
                              </div>
                              <div className="text-opacity">
                                <h3 className="heading-13">SaVaGe</h3>
                                <h3 className="sliders_text2-3">
                                  Community Manager
                                </h3>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>

                        <SplideSlide>
                          <div
                            role="listitem"
                            className="splide__slide five-cards w-dyn-item"
                          >
                            <div className="slider-tall">
                              <div className="slider-tall_img">
                                <img
                                  src={Team2_2}
                                  loading="lazy"
                                  alt="Gutter Ric"
                                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 97vw, (max-width: 991px) 52vw, (max-width: 1279px) 36vw, (max-width: 1439px) 37vw, (max-width: 1919px) 531.13134765625px, 28vw"
                                  className="slider-tall_photo"
                                />
                              </div>
                              <div className="text-opacity">
                                <h3 className="heading-13">BigWilly</h3>
                                <h3 className="sliders_text2-3">
                                  Community Manager
                                </h3>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>

                        <SplideSlide>
                          <div
                            role="listitem"
                            className="splide__slide five-cards w-dyn-item"
                          >
                            <div className="slider-tall">
                              <div className="slider-tall_img">
                                <img
                                  src={Team2_3}
                                  loading="lazy"
                                  alt="Gutter Mitch"
                                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 97vw, (max-width: 991px) 52vw, (max-width: 1279px) 36vw, (max-width: 1439px) 37vw, (max-width: 1919px) 531.13134765625px, 28vw"
                                  className="slider-tall_photo"
                                />
                              </div>
                              <div className="text-opacity">
                                <h3 className="heading-13">Farmer Boy</h3>
                                <h3 className="sliders_text2-3">
                                  Community Manager
                                </h3>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>

                        <SplideSlide>
                          <div
                            role="listitem"
                            className="splide__slide five-cards w-dyn-item"
                          >
                            <div className="slider-tall">
                              <div className="slider-tall_img">
                                <img
                                  src={Team2_4}
                                  loading="lazy"
                                  alt="Gutter Dan"
                                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 97vw, (max-width: 991px) 52vw, (max-width: 1279px) 36vw, (max-width: 1439px) 37vw, (max-width: 1919px) 531.13134765625px, 28vw"
                                  className="slider-tall_photo"
                                />
                              </div>
                              <div className="text-opacity">
                                <h3 className="heading-13">Calcifer</h3>
                                <h3 className="sliders_text2-3">
                                  Community Manager
                                </h3>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div
                            role="listitem"
                            className="splide__slide five-cards w-dyn-item"
                          >
                            <div className="slider-tall">
                              <div className="slider-tall_img">
                                <img
                                  src={Team2_5}
                                  loading="lazy"
                                  alt="Gutter Wang"
                                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 97vw, (max-width: 991px) 52vw, (max-width: 1279px) 36vw, (max-width: 1439px) 37vw, (max-width: 1919px) 531.13134765625px, 28vw"
                                  className="slider-tall_photo"
                                />
                              </div>
                              <div className="text-opacity">
                                <h3 className="heading-13">Banymania</h3>
                                <h3 className="sliders_text2-3">
                                  Community Manager
                                </h3>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div
                            role="listitem"
                            className="splide__slide five-cards w-dyn-item"
                          >
                            <div className="slider-tall">
                              <div className="slider-tall_img">
                                <img
                                  src={Team2_6}
                                  loading="lazy"
                                  alt="Gutter Ikarlee"
                                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 97vw, (max-width: 991px) 52vw, (max-width: 1279px) 36vw, (max-width: 1439px) 37vw, (max-width: 1919px) 531.13134765625px, 28vw"
                                  className="slider-tall_photo"
                                />
                              </div>
                              <div className="text-opacity">
                                <h3 className="heading-13">Phillipe</h3>
                                <h3 className="sliders_text2-3">
                                  Community Manager
                                </h3>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div
                            role="listitem"
                            className="splide__slide five-cards w-dyn-item"
                          >
                            <div className="slider-tall">
                              <div className="slider-tall_img">
                                <img
                                  src={Team2_7}
                                  loading="lazy"
                                  alt="Big Stupid"
                                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 97vw, (max-width: 991px) 52vw, (max-width: 1279px) 36vw, (max-width: 1439px) 37vw, (max-width: 1919px) 531.13134765625px, 28vw"
                                  className="slider-tall_photo"
                                />
                              </div>
                              <div className="text-opacity">
                                <h3 className="heading-13">Lyle</h3>
                                <h3 className="sliders_text2-3">
                                  Head Moderator
                                </h3>
                              </div>
                            </div>
                          </div>
                        </SplideSlide>
                      </Splide>
                    </div>
                  </div>
                  <div className="splide__arrows-2">
                    <div className="splide__embed-2 w-embed">
                      <button className="splide__arrow splide__arrow--prev"></button>
                    </div>
                    <div className="splide__embed-2 w-embed">
                      <button className="splide__arrow splide__arrow--next"></button>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div> */}
        </div>
        <div className="slider-css w-embed">
          <style></style>
        </div>
      </section>

      <section className="Bgcolor" id="faq" style={{ paddingTop: "50px" }}>
        <div
          data-w-id="dabaebf5-2627-f020-656e-ee8ea91ee93d"
          className="wf-section"
        >
          <div className="paddings">
            <div className="div-block-579">
              <div
                id="w-node-d8bd53d2-a5a9-26d3-be2e-4fffa5acd883-6aec71e4"
                className="div-block-661"
              >
                <div className="tabs">FAQ</div>
                <h1 className="heading-18">
                  get the <span className="text-span-5">facts</span>
                </h1>
              </div>
              <div id="w-node-_6e06d152-e86c-e5d8-3621-00aeafbb3f19-6aec71e4">
                <div>
                  <div className="div-block-52">
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e6e-6aec71e4"
                      className="splitter white"
                    ></div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e6f-6aec71e4"
                      className="div-block-53"
                    >
                      <div
                        data-click="faq"
                        id="w-node-ab8094d2-1672-ce00-6052-069e73082e70-6aec71e4"
                        className="c-faq-item"
                      >
                        <div className="c-faq-q">
                          <div className="c-faq-q-text">
                            <div className="text-block-17">
                              {" "}
                              WHAT IS THE DUNK NFT PROJECT?
                            </div>
                          </div>
                          <div className="c-faq-icon">
                            <div className="_w-faq-icon">
                              <div className="faq-stripe-1"></div>
                              <div className="faq-stripe-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="c-faq-a">
                          <div className="c-faq-a-text">
                            <p
                              id="w-node-ab8094d2-1672-ce00-6052-069e73082e7b-6aec71e4"
                              className="normal-text-2 principle-paragraph"
                            >
                              {" "}
                              DUNKs are a private collection of 10,000 NFTs
                              unique digital collectibles from over 100+ hand
                              drawn traits. Each NFT is stored as ERC-721 tokens
                              on the Ethereum blockchain and hosted on
                              IPFS/Pinata. We aim to become one of the leading
                              NFT projects within the Sports world.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e7d-6aec71e4"
                      className="splitter white"
                    ></div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e7e-6aec71e4"
                      className="div-block-53"
                    >
                      <div
                        data-click="faq"
                        id="w-node-ab8094d2-1672-ce00-6052-069e73082e7f-6aec71e4"
                        className="c-faq-item"
                      >
                        <div className="c-faq-q">
                          <div className="c-faq-q-text">
                            <div className="text-block-17">
                              {" "}
                              WHAT ARE THESE ART PIECES MADE OF?
                            </div>
                          </div>
                          <div className="c-faq-icon">
                            <div className="_w-faq-icon">
                              <div className="faq-stripe-1"></div>
                              <div className="faq-stripe-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="c-faq-a">
                          <div className="c-faq-a-text">
                            <ol className="ol">
                              <li> Gorillas are cool.</li>
                              <li>
                                DUNK characters are generated as unique NFTs
                                based on more than a hundred different variable
                                assets: Skins, Headgear, Eyegear, Tops, Shorts,
                                Shoes, Background, Accessories, Face Traits, and
                                of course faction groups randomly generated
                                through over 100 different individual assets.
                                Your exclusive NFTs will be entirely individual.
                              </li>
                              {/* <li>
                                {" "}
                                We believe NFTs should represent more than a
                                JPEG in your wallet. This is a cultural
                                revolution. We have built the first ever dynamic
                                income generating NFTs on solana.
                              </li> */}
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e8c-6aec71e4"
                      className="splitter white"
                    ></div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e8d-6aec71e4"
                      className="div-block-53"
                    >
                      <div
                        data-click="faq"
                        id="w-node-ab8094d2-1672-ce00-6052-069e73082e8e-6aec71e4"
                        className="c-faq-item"
                      >
                        <div className="c-faq-q">
                          <div className="c-faq-q-text">
                            <div className="text-block-17">
                              {" "}
                              HOW MANY MINTABLE NFTS ARE THERE?
                            </div>
                          </div>
                          <div className="c-faq-icon">
                            <div className="_w-faq-icon">
                              <div className="faq-stripe-1"></div>
                              <div className="faq-stripe-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="c-faq-a">
                          <div className="c-faq-a-text">
                            <p
                              id="w-node-ab8094d2-1672-ce00-6052-069e73082e99-6aec71e4"
                              className="normal-text-2 principle-paragraph"
                            >
                              {" "}
                              There will be 10,000 mintable NFTs available as
                              ERC-721 tokens on the Ethereum blockchain.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e9b-6aec71e4"
                      className="splitter white"
                    ></div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e9c-6aec71e4"
                      className="div-block-53"
                    >
                      <div
                        data-click="faq"
                        id="w-node-ab8094d2-1672-ce00-6052-069e73082e9d-6aec71e4"
                        className="c-faq-item"
                      >
                        <div className="c-faq-q">
                          <div className="c-faq-q-text">
                            <div className="text-block-17">
                              {" "}
                              WHAT SHOULD I DO TO GAIN ACCESS TO PRE-MINT?
                            </div>
                          </div>
                          <div className="c-faq-icon">
                            <div className="_w-faq-icon">
                              <div className="faq-stripe-1"></div>
                              <div className="faq-stripe-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="c-faq-a">
                          <div className="c-faq-a-text">
                            <p
                              id="w-node-ab8094d2-1672-ce00-6052-069e73082ea8-6aec71e4"
                              className="normal-text-2 principle-paragraph"
                            >
                              {" "}
                              There will be 2000 WL spots. You can win pre-mint
                              access in multiple Allstar Metaverse Discord
                              channels, so be sure you know about everything
                              happening there and actively engage in the
                              communication. It is also possible that one of our
                              moderators will whitelist you for your activity
                              and contributions. NBA Memes will also be
                              rewarded!
                              <br />
                              <br />
                              P.S. There are 300 LEGENDARIES and 700 ALLSTAR
                              NFTs to be minted in the first 5K! Anyone that
                              mints a LEGENDARY DUNK will be able to claim
                              vested $STAR tokens as soon as $STAR token goes
                              live.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082eaa-6aec71e4"
                      className="splitter white"
                    ></div>

                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e9c-6aec71e4"
                      className="div-block-53"
                    >
                      <div
                        data-click="faq"
                        id="w-node-ab8094d2-1672-ce00-6052-069e73082e9d-6aec71e4"
                        className="c-faq-item"
                      >
                        <div className="c-faq-q">
                          <div className="c-faq-q-text">
                            <div className="text-block-17">
                              {" "}
                              HOW MUCH WILL THE MINT COST?
                            </div>
                          </div>
                          <div className="c-faq-icon">
                            <div className="_w-faq-icon">
                              <div className="faq-stripe-1"></div>
                              <div className="faq-stripe-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="c-faq-a">
                          <div className="c-faq-a-text">
                            <p
                              id="w-node-ab8094d2-1672-ce00-6052-069e73082ea8-6aec71e4"
                              className="normal-text-2 principle-paragraph"
                            >
                              {" "}
                              0 ETH - (FREE MINT) A minimum of 0.01 Ethereum
                              balance is required to mint though. You won't be
                              charged. It is just an anti-bot measure to protect
                              the community from bots during public mint.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082eaa-6aec71e4"
                      className="splitter white"
                    ></div>

                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e9c-6aec71e4"
                      className="div-block-53"
                    >
                      <div
                        data-click="faq"
                        id="w-node-ab8094d2-1672-ce00-6052-069e73082e9d-6aec71e4"
                        className="c-faq-item"
                      >
                        <div className="c-faq-q">
                          <div className="c-faq-q-text">
                            <div className="text-block-17">
                              {" "}
                              HOW MANY CAN I MINT IN ONE TRANSACTION?
                            </div>
                          </div>
                          <div className="c-faq-icon">
                            <div className="_w-faq-icon">
                              <div className="faq-stripe-1"></div>
                              <div className="faq-stripe-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="c-faq-a">
                          <div className="c-faq-a-text">
                            <p
                              id="w-node-ab8094d2-1672-ce00-6052-069e73082ea8-6aec71e4"
                              className="normal-text-2 principle-paragraph"
                            >
                              {" "}
                              There is a maximum of 1 NFT that can be minted per
                              transaction/wallet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082eaa-6aec71e4"
                      className="splitter white"
                    ></div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e9c-6aec71e4"
                      className="div-block-53"
                    >
                      <div
                        data-click="faq"
                        id="w-node-ab8094d2-1672-ce00-6052-069e73082e9d-6aec71e4"
                        className="c-faq-item"
                      >
                        <div className="c-faq-q">
                          <div className="c-faq-q-text">
                            <div className="text-block-17">
                              {" "}
                              WHAT IS THE BENEFIT OF HOLDING A DUNK NFT?
                            </div>
                          </div>
                          <div className="c-faq-icon">
                            <div className="_w-faq-icon">
                              <div className="faq-stripe-1"></div>
                              <div className="faq-stripe-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="c-faq-a">
                          <div className="c-faq-a-text">
                            <p
                              id="w-node-ab8094d2-1672-ce00-6052-069e73082ea8-6aec71e4"
                              className="normal-text-2 principle-paragraph"
                            >
                              {" "}
                              DUNK NFT is your virtual ticket into a community
                              of like-minded Web3 enthusiasts that want to stand
                              up against online Trolls. Our NFTs will serve as a
                              community standard for athlethes, entrepreneurs,
                              artists, investors, and collectors. DUNKs will
                              serve as tickets to our exclusive events. These
                              events will be held quarterly and prizes such as
                              tokens, Designer Goods, and signed training gear
                              from world class athletes. We will also be giving
                              away a pair of NBA Games tickets to a randomly
                              selected holder every month as a token of our
                              appreciation! A community wallet will be made and
                              members/collectors can vote on what the funds
                              should be used for to drive community growth.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082eaa-6aec71e4"
                      className="splitter white"
                    ></div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e9c-6aec71e4"
                      className="div-block-53"
                    >
                      <div
                        data-click="faq"
                        id="w-node-ab8094d2-1672-ce00-6052-069e73082e9d-6aec71e4"
                        className="c-faq-item"
                      >
                        <div className="c-faq-q">
                          <div className="c-faq-q-text">
                            <div className="text-block-17">
                              {" "}
                              HOW DO I GENERATE INCOME REWARDS WITH MY NFTS?s
                            </div>
                          </div>
                          <div className="c-faq-icon">
                            <div className="_w-faq-icon">
                              <div className="faq-stripe-1"></div>
                              <div className="faq-stripe-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="c-faq-a">
                          <div className="c-faq-a-text">
                            <p
                              id="w-node-ab8094d2-1672-ce00-6052-069e73082ea8-6aec71e4"
                              className="normal-text-2 principle-paragraph"
                            >
                              {" "}
                              We will be releasing an NFT staking and farming
                              platform that will unlock the utility of DUNK NFTs
                              and supercharge holders' earning potential. Stay
                              tuned for more updates!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082eaa-6aec71e4"
                      className="splitter white"
                    ></div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e9c-6aec71e4"
                      className="div-block-53"
                    >
                      <div
                        data-click="faq"
                        id="w-node-ab8094d2-1672-ce00-6052-069e73082e9d-6aec71e4"
                        className="c-faq-item"
                      >
                        <div className="c-faq-q">
                          <div className="c-faq-q-text">
                            <div className="text-block-17">
                              {" "}
                              IS THIS AN INVESTMENT?
                            </div>
                          </div>
                          <div className="c-faq-icon">
                            <div className="_w-faq-icon">
                              <div className="faq-stripe-1"></div>
                              <div className="faq-stripe-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="c-faq-a">
                          <div className="c-faq-a-text">
                            <p
                              id="w-node-ab8094d2-1672-ce00-6052-069e73082ea8-6aec71e4"
                              className="normal-text-2 principle-paragraph"
                            >
                              No this is not an investment! we do not speculate
                              on price going up or down. This is a ticket into
                              Allstar Metaverse and the team will try to add as
                              much value as possible for holding these virtual
                              tickets.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082eaa-6aec71e4"
                      className="splitter white"
                    ></div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e9c-6aec71e4"
                      className="div-block-53"
                    >
                      <div
                        data-click="faq"
                        id="w-node-ab8094d2-1672-ce00-6052-069e73082e9d-6aec71e4"
                        className="c-faq-item"
                      >
                        <div className="c-faq-q">
                          <div className="c-faq-q-text">
                            <div className="text-block-17">
                              {" "}
                              WILL THERE BE A DOA?
                            </div>
                          </div>
                          <div className="c-faq-icon">
                            <div className="_w-faq-icon">
                              <div className="faq-stripe-1"></div>
                              <div className="faq-stripe-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="c-faq-a">
                          <div className="c-faq-a-text">
                            <p
                              id="w-node-ab8094d2-1672-ce00-6052-069e73082ea8-6aec71e4"
                              className="normal-text-2 principle-paragraph"
                            >
                              Allstar Metaverse is a community-driven project
                              and for that a DOA has already been implemented.
                              Decisions are made by consensus between community
                              members and devs. Everyone has a say in the future
                              of the project.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082eaa-6aec71e4"
                      className="splitter white"
                    ></div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e9c-6aec71e4"
                      className="div-block-53"
                    >
                      <div
                        data-click="faq"
                        id="w-node-ab8094d2-1672-ce00-6052-069e73082e9d-6aec71e4"
                        className="c-faq-item"
                      >
                        <div className="c-faq-q">
                          <div className="c-faq-q-text">
                            <div className="text-block-17">
                              {" "}
                              ARE YOU AFFILIATED WITH THE OFFICIAL NBA (NATIONAL
                              BASKETBALL ASSOCIATION)?
                            </div>
                          </div>
                          <div className="c-faq-icon">
                            <div className="_w-faq-icon">
                              <div className="faq-stripe-1"></div>
                              <div className="faq-stripe-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="c-faq-a">
                          <div className="c-faq-a-text">
                            <p
                              id="w-node-ab8094d2-1672-ce00-6052-069e73082ea8-6aec71e4"
                              className="normal-text-2 principle-paragraph"
                            >
                              No, we are not affiliated with the NBA. Allstar
                              Metaverse is a fan-made project that celebrates
                              the excitement and athleticism of slam dunks.
                              However, we have many official partnerships with
                              various NBA players.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082eaa-6aec71e4"
                      className="splitter white"
                    ></div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e9c-6aec71e4"
                      className="div-block-53"
                    >
                      <div
                        data-click="faq"
                        id="w-node-ab8094d2-1672-ce00-6052-069e73082e9d-6aec71e4"
                        className="c-faq-item"
                      >
                        <div className="c-faq-q">
                          <div className="c-faq-q-text">
                            <div className="text-block-17">
                              {" "}
                              WHERE CAN I VIEW MY NFTS?
                            </div>
                          </div>
                          <div className="c-faq-icon">
                            <div className="_w-faq-icon">
                              <div className="faq-stripe-1"></div>
                              <div className="faq-stripe-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="c-faq-a">
                          <div className="c-faq-a-text">
                            <p
                              id="w-node-ab8094d2-1672-ce00-6052-069e73082ea8-6aec71e4"
                              className="normal-text-2 principle-paragraph"
                            >
                              Once minted or bought simply connect to your
                              OpenSea account to view your NFTs.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082eaa-6aec71e4"
                      className="splitter white"
                    ></div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082e9c-6aec71e4"
                      className="div-block-53"
                    >
                      <div
                        data-click="faq"
                        id="w-node-ab8094d2-1672-ce00-6052-069e73082e9d-6aec71e4"
                        className="c-faq-item"
                      >
                        <div className="c-faq-q">
                          <div className="c-faq-q-text">
                            <div className="text-block-17">
                              {" "}
                              HOW CAN I CONTACT THE ALLSTAR METAVERSE TEAM?
                            </div>
                          </div>
                          <div className="c-faq-icon">
                            <div className="_w-faq-icon">
                              <div className="faq-stripe-1"></div>
                              <div className="faq-stripe-2"></div>
                            </div>
                          </div>
                        </div>
                        <div className="c-faq-a">
                          <div className="c-faq-a-text">
                            <p
                              id="w-node-ab8094d2-1672-ce00-6052-069e73082ea8-6aec71e4"
                              className="normal-text-2 principle-paragraph"
                            >
                              We are all super active on Discord, Instagram and
                              Twitter building the community. Ask any questions
                              you may have for us in Discord or Twitter ideally.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="w-node-ab8094d2-1672-ce00-6052-069e73082eaa-6aec71e4"
                      className="splitter white"
                    ></div>
                  </div>
                </div>

                {/* FAQ END */}
                <div
                  data-duration-in="300"
                  data-duration-out="100"
                  data-current="eCommerce"
                  data-easing="ease"
                  className="tabs-2 w-tabs"
                >
                  <div className="tabs-menu w-tab-menu">
                    <a
                      data-w-tab="Corporate Website"
                      className="tab-link-tab-1 w-inline-block w-tab-link"
                    >
                      <div className="tabs">FAQ</div>
                    </a>
                    <a
                      data-w-tab="eCommerce"
                      className="tab-link-tab-2 w-inline-block w-tab-link w--current"
                    ></a>
                  </div>
                  <div className="tabs-content w-tab-content">
                    <div data-w-tab="Corporate Website" className="w-tab-pane">
                      <div className="div-block-52">
                        <div
                          id="w-node-_0ad1210b-fd59-3b77-c1a0-0dd0d149cdad-6aec71e4"
                          className="splitter white"
                        ></div>
                        <div
                          id="w-node-_0ad1210b-fd59-3b77-c1a0-0dd0d149cdae-6aec71e4"
                          className="div-block-53"
                        >
                          <div
                            data-click="faq"
                            id="w-node-_0ad1210b-fd59-3b77-c1a0-0dd0d149cdaf-6aec71e4"
                            className="c-faq-item"
                          >
                            <div className="c-faq-q">
                              <div className="c-faq-q-text">
                                <div className="text-block-17">
                                  What is the Gutter Cat Gang?
                                </div>
                              </div>
                              <div className="c-faq-icon">
                                <div className="_w-faq-icon">
                                  <div className="faq-stripe-1"></div>
                                  <div className="faq-stripe-2"></div>
                                </div>
                              </div>
                            </div>
                            <div className="c-faq-a">
                              <div className="c-faq-a-text">
                                <p
                                  id="w-node-_0ad1210b-fd59-3b77-c1a0-0dd0d149cdc4-6aec71e4"
                                  className="normal-text-2 principle-paragraph"
                                >
                                  The Gutter Cat Gang is a collection of 3000
                                  unique Gutter Cat NFTs who live in the
                                  post-apocalyptic shatters of society on the
                                  ETH blockchain. Ownership includes creative
                                  &amp; commercial rights of your Gutter Cat as
                                  well as initiation into the Gang.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="w-node-_0ad1210b-fd59-3b77-c1a0-0dd0d149cdc6-6aec71e4"
                          className="splitter white"
                        ></div>
                        <div
                          id="w-node-_63301320-7809-3d03-2db1-5db123894a6f-6aec71e4"
                          className="div-block-53"
                        >
                          <div
                            data-click="faq"
                            id="w-node-_63301320-7809-3d03-2db1-5db123894a70-6aec71e4"
                            className="c-faq-item"
                          >
                            <div className="c-faq-q">
                              <div className="c-faq-q-text">
                                <div className="text-block-17">Wencatz?</div>
                              </div>
                              <div className="c-faq-icon">
                                <div className="_w-faq-icon">
                                  <div className="faq-stripe-1"></div>
                                  <div className="faq-stripe-2"></div>
                                </div>
                              </div>
                            </div>
                            <div className="c-faq-a">
                              <div className="c-faq-a-text">
                                <p
                                  id="w-node-_63301320-7809-3d03-2db1-5db123894a7b-6aec71e4"
                                  className="normal-text-2 principle-paragraph"
                                >
                                  The Gutter Cat Gang is a collection of 3000
                                  unique Gutter Cat NFTs who live in the
                                  post-apocalyptic shatters of society on the
                                  ETH blockchain. Ownership includes creative
                                  &amp; commercial rights of your Gutter Cat as
                                  well as initiation into the Gang.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="w-node-_0a951441-8075-4c3d-fc1e-471728ad45e4-6aec71e4"
                          className="splitter white"
                        ></div>
                        <div
                          id="w-node-f79b5f43-7a73-b5d7-2df9-11df700c754e-6aec71e4"
                          className="div-block-53"
                        >
                          <div
                            data-click="faq"
                            id="w-node-f79b5f43-7a73-b5d7-2df9-11df700c754f-6aec71e4"
                            className="c-faq-item"
                          >
                            <div className="c-faq-q">
                              <div className="c-faq-q-text">
                                <div className="text-block-17">
                                  How much does a Gutter Cat cost?
                                </div>
                              </div>
                              <div className="c-faq-icon">
                                <div className="_w-faq-icon">
                                  <div className="faq-stripe-1"></div>
                                  <div className="faq-stripe-2"></div>
                                </div>
                              </div>
                            </div>
                            <div className="c-faq-a">
                              <div className="c-faq-a-text">
                                <p
                                  id="w-node-f79b5f43-7a73-b5d7-2df9-11df700c755a-6aec71e4"
                                  className="normal-text-2 principle-paragraph"
                                >
                                  The Gutter Cat Gang is a collection of 3000
                                  unique Gutter Cat NFTs who live in the
                                  post-apocalyptic shatters of society on the
                                  ETH blockchain. Ownership includes creative
                                  &amp; commercial rights of your Gutter Cat as
                                  well as initiation into the Gang.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="w-node-_4b54ec85-90ec-0a3b-5659-428f97505df1-6aec71e4"
                          className="splitter white"
                        ></div>
                        <div
                          id="w-node-_38124952-2a75-be23-c7eb-ee4849d3cfe5-6aec71e4"
                          className="div-block-53"
                        >
                          <div
                            data-click="faq"
                            id="w-node-_38124952-2a75-be23-c7eb-ee4849d3cfe6-6aec71e4"
                            className="c-faq-item"
                          >
                            <div className="c-faq-q">
                              <div className="c-faq-q-text">
                                <div className="text-block-17">
                                  How can I get a Gutter Cat?
                                </div>
                              </div>
                              <div className="c-faq-icon">
                                <div className="_w-faq-icon">
                                  <div className="faq-stripe-1"></div>
                                  <div className="faq-stripe-2"></div>
                                </div>
                              </div>
                            </div>
                            <div className="c-faq-a">
                              <div className="c-faq-a-text">
                                <p
                                  id="w-node-_38124952-2a75-be23-c7eb-ee4849d3cff1-6aec71e4"
                                  className="normal-text-2 principle-paragraph"
                                >
                                  The Gutter Cat Gang is a collection of 3000
                                  unique Gutter Cat NFTs who live in the
                                  post-apocalyptic shatters of society on the
                                  ETH blockchain. Ownership includes creative
                                  &amp; commercial rights of your Gutter Cat as
                                  well as initiation into the Gang.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="w-node-a0e3af9e-7d25-49df-9a79-042f357ffff8-6aec71e4"
                          className="splitter white"
                        ></div>
                        <div
                          id="w-node-a23f092e-153b-5632-bd84-71f5a3a74e48-6aec71e4"
                          className="div-block-53"
                        >
                          <div
                            data-click="faq"
                            id="w-node-a23f092e-153b-5632-bd84-71f5a3a74e49-6aec71e4"
                            className="c-faq-item"
                          >
                            <div className="c-faq-q">
                              <div className="c-faq-q-text">
                                <div className="text-block-17">
                                  How were the Gutter Cats created?
                                </div>
                              </div>
                              <div className="c-faq-icon">
                                <div className="_w-faq-icon">
                                  <div className="faq-stripe-1"></div>
                                  <div className="faq-stripe-2"></div>
                                </div>
                              </div>
                            </div>
                            <div className="c-faq-a">
                              <div className="c-faq-a-text">
                                <p
                                  id="w-node-a23f092e-153b-5632-bd84-71f5a3a74e54-6aec71e4"
                                  className="normal-text-2 principle-paragraph"
                                >
                                  The Gutter Cat Gang is a collection of 3000
                                  unique Gutter Cat NFTs who live in the
                                  post-apocalyptic shatters of society on the
                                  ETH blockchain. Ownership includes creative
                                  &amp; commercial rights of your Gutter Cat as
                                  well as initiation into the Gang.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="w-node-adbaa998-4591-9b42-dafd-a6c6f8c971e4-6aec71e4"
                          className="splitter white"
                        ></div>
                        <div
                          id="w-node-fe9c99eb-e0b8-c3f3-9181-82c64363ab99-6aec71e4"
                          className="div-block-53"
                        >
                          <div
                            data-click="faq"
                            id="w-node-fe9c99eb-e0b8-c3f3-9181-82c64363ab9a-6aec71e4"
                            className="c-faq-item"
                          >
                            <div className="c-faq-q">
                              <div className="c-faq-q-text">
                                <div className="text-block-17">
                                  What&#x27;s the Gutter Cat Gang&#x27;s origin?
                                </div>
                              </div>
                              <div className="c-faq-icon">
                                <div className="_w-faq-icon">
                                  <div className="faq-stripe-1"></div>
                                  <div className="faq-stripe-2"></div>
                                </div>
                              </div>
                            </div>
                            <div className="c-faq-a">
                              <div className="c-faq-a-text">
                                <p
                                  id="w-node-fe9c99eb-e0b8-c3f3-9181-82c64363aba5-6aec71e4"
                                  className="normal-text-2 principle-paragraph"
                                >
                                  The Gutter Cat Gang is a collection of 3000
                                  unique Gutter Cat NFTs who live in the
                                  post-apocalyptic shatters of society on the
                                  ETH blockchain. Ownership includes creative
                                  &amp; commercial rights of your Gutter Cat as
                                  well as initiation into the Gang.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="w-node-_3dc7d6a1-6ba6-5467-2ee8-faa8563b7c62-6aec71e4"
                          className="splitter white"
                        ></div>
                      </div>
                    </div>
                    <div
                      data-w-tab="eCommerce"
                      className="w-tab-pane w--tab-active"
                    >
                      <div className="div-block-52">
                        <div
                          id="w-node-_43340987-b859-93db-28aa-4541cb8bb8fa-6aec71e4"
                          className="splitter white"
                        ></div>
                        <div
                          id="w-node-_43340987-b859-93db-28aa-4541cb8bb8fb-6aec71e4"
                          className="div-block-53"
                        >
                          <div
                            data-click="faq"
                            id="w-node-_43340987-b859-93db-28aa-4541cb8bb8fc-6aec71e4"
                            className="c-faq-item"
                          >
                            <div className="c-faq-q">
                              <div className="c-faq-q-text">
                                <div className="text-block-17">
                                  What is the Gutter Cat Gang?
                                </div>
                              </div>
                              <div className="c-faq-icon">
                                <div className="_w-faq-icon">
                                  <div className="faq-stripe-1"></div>
                                  <div className="faq-stripe-2"></div>
                                </div>
                              </div>
                            </div>
                            <div className="c-faq-a">
                              <div className="c-faq-a-text">
                                <p
                                  id="w-node-_43340987-b859-93db-28aa-4541cb8bb907-6aec71e4"
                                  className="normal-text-2 principle-paragraph"
                                >
                                  The Gutter Cat Gang is a collection of 3000
                                  unique Gutter Cat NFTs who live in the
                                  post-apocalyptic shatters of society on the
                                  ETH blockchain. Ownership includes creative
                                  &amp; commercial rights of your Gutter Cat as
                                  well as initiation into the Gang.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="w-node-_43340987-b859-93db-28aa-4541cb8bb909-6aec71e4"
                          className="splitter white"
                        ></div>
                        <div
                          id="w-node-_43340987-b859-93db-28aa-4541cb8bb928-6aec71e4"
                          className="div-block-53"
                        >
                          <div
                            data-click="faq"
                            id="w-node-_43340987-b859-93db-28aa-4541cb8bb929-6aec71e4"
                            className="c-faq-item"
                          >
                            <div className="c-faq-q">
                              <div className="c-faq-q-text">
                                <div className="text-block-17">
                                  How can I join the DAO?
                                </div>
                              </div>
                              <div className="c-faq-icon">
                                <div className="_w-faq-icon">
                                  <div className="faq-stripe-1"></div>
                                  <div className="faq-stripe-2"></div>
                                </div>
                              </div>
                            </div>
                            <div className="c-faq-a">
                              <div className="c-faq-a-text">
                                <p
                                  id="w-node-_43340987-b859-93db-28aa-4541cb8bb934-6aec71e4"
                                  className="normal-text-2 principle-paragraph"
                                >
                                  To enter the DAO you must hold at least one
                                  Gutter Cat in your wallet. Gutter Cats can be
                                  found for sale on nearly all NFT secondary
                                  markets, with most being predominantly on
                                  OpenSea.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="w-node-_43340987-b859-93db-28aa-4541cb8bb936-6aec71e4"
                          className="splitter white"
                        ></div>
                        <div
                          id="w-node-_43340987-b859-93db-28aa-4541cb8bb937-6aec71e4"
                          className="div-block-53"
                        >
                          <div
                            data-click="faq"
                            id="w-node-_43340987-b859-93db-28aa-4541cb8bb938-6aec71e4"
                            className="c-faq-item"
                          >
                            <div className="c-faq-q">
                              <div className="c-faq-q-text">
                                <div className="text-block-17">
                                  How were the Gutter Cats created?
                                </div>
                              </div>
                              <div className="c-faq-icon">
                                <div className="_w-faq-icon">
                                  <div className="faq-stripe-1"></div>
                                  <div className="faq-stripe-2"></div>
                                </div>
                              </div>
                            </div>
                            <div className="c-faq-a">
                              <div className="c-faq-a-text">
                                <p
                                  id="w-node-_43340987-b859-93db-28aa-4541cb8bb943-6aec71e4"
                                  className="normal-text-2 principle-paragraph"
                                >
                                  Each Gutter Cat is algorithmically generated
                                  by combining 123 properties with varying
                                  rarities in nine categories (Furs,
                                  Backgrounds, Eyes, Mouths, Shirts, Hats,
                                  Earrings, Necklaces, &amp; Beards).
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="w-node-_43340987-b859-93db-28aa-4541cb8bb945-6aec71e4"
                          className="splitter white"
                        ></div>
                        <div
                          id="w-node-_43340987-b859-93db-28aa-4541cb8bb946-6aec71e4"
                          className="div-block-53"
                        >
                          <div
                            data-click="faq"
                            id="w-node-_43340987-b859-93db-28aa-4541cb8bb947-6aec71e4"
                            className="c-faq-item"
                          >
                            <div className="c-faq-q">
                              <div className="c-faq-q-text">
                                <div className="text-block-17">
                                  What&#x27;s the Gutter Cat Gang&#x27;s origin?
                                </div>
                              </div>
                              <div className="c-faq-icon">
                                <div className="_w-faq-icon">
                                  <div className="faq-stripe-1"></div>
                                  <div className="faq-stripe-2"></div>
                                </div>
                              </div>
                            </div>
                            <div className="c-faq-a">
                              <div className="c-faq-a-text">
                                <p
                                  id="w-node-_43340987-b859-93db-28aa-4541cb8bb952-6aec71e4"
                                  className="normal-text-2 principle-paragraph"
                                >
                                  The year is 2050. Humans are an interplanetary
                                  species &amp; have all but abandoned the
                                  post-apocalyptic shatters of society on earth.
                                  Cats have taken over. One crime-ridden,
                                  nondescript inner city is inhabited by a group
                                  of cats collectively known as the Gutter Cats.
                                  This is their story.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id="w-node-bca34dc3-8b41-5a16-51c1-1ecb85632e40-6aec71e4"
                          className="splitter white"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Menu right>
        <div
          className="custom-menu"
          style={{ display: "flex", flexDirection: "column", gap: "15px" }}
        >
          <img src={Logo} alt="logo" />
          <a href="/" aria-current="page" className="menu-item">
            Home
          </a>
          <a href="#ecosystem " className="menu-item">
            Ecosystem
          </a>
          <a href="#mint" className="menu-item">
            Mint
          </a>
          <a
            href="https://opensea.io/collection/allstar-metaverse"
            target="_blank"
            rel="noreferrer"
            className="menu-item"
          >
            Marketplace
          </a>
          <a href="#faq" className="menu-item">
            FAQ
          </a>
          <a href="#charts" className="menu-item">
            Charts
          </a>
          <a
            href="https://docs.allstarmetaverse.org/"
            target={"_blank"}
            className="menu-item"
            style={{ marginBottom: "30px" }}
            rel="noreferrer"
          >
            Whitepaper
          </a>
          <a
            href="https://mint.allstarmetaverse.org/"
            rel="noreferrer"
            target={"_blank"}
            className="link-block hero-btn w-inline-block"
          >
            <div className="div-block-537 hero-btn">
              <div className="button-text"> Mint</div>
            </div>
          </a>
          <div
            className="social-icons"
            style={{ display: "flex", gap: "10px" }}
          >
            <a
              href="https://twitter.com/AllstarEth"
              target="_blank"
              className="link-block-80 w-inline-block"
              rel="noreferrer"
            >
              <img
                src={Twitter}
                loading="lazy"
                alt="Twitter Logo"
                className="image-3"
              />
            </a>
            <a
              href="https://t.me/AllstarMetaverse"
              target="_blank"
              className="link-block-80 w-inline-block"
              rel="noreferrer"
            >
              <img
                src={Telegram}
                style={{ filter: "invert(29%) brightness(180%)" }}
                loading="lazy"
                alt="Telegram logo"
                className="image-3"
              />
            </a>
            <a
              href="https://docs.allstarmetaverse.org/"
              target="_blank"
              style={{ filter: "brightness(80%) invert(80%)" }}
              className="link-block-80 w-inline-block"
              rel="noreferrer"
            >
              <img
                src={GitBook}
                loading="lazy"
                alt="Gitbook logo"
                className="image-3 full-screen-menu"
              />
            </a>
            <a
              href="https://discord.gg/allstarmetaverse"
              target="_blank"
              className="link-block-80 last w-inline-block"
              rel="noreferrer"
            >
              <img
                src={Discord}
                loading="lazy"
                alt="Discord logo"
                className="image-3"
              />
            </a>
          </div>
        </div>
      </Menu>
    </>
  );
}

export default Home;
